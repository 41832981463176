import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { AuthWidget, AuthFormType, useShoppingCart, useAuth } from '@ecommerce/shared'
import { Icon } from '../components/Icon/Icon'
import Layout from '../components/Layout/LayoutStaticPage'
import { BackButton } from '../components/Checkout/Checkout.styled'
import { Inner, Wrapper } from './cuenta-activada'
import withPageTransition from '../components/withPageTransition'
import { useUserLoginHandler } from '../hooks/useUserLoginHandler'
import { sendLoginEvent, sendPageViewEvent } from '../utils/events'

const LoginPage = () => {
  const { UserLoginHandlerAlertComponent, userLoginCartHandler } = useUserLoginHandler()
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  const onSignInFinished = () => {
    const cart = getConnectifCart()
    const entityInfo = getEntityInfo()
    sendLoginEvent(cart, entityInfo)
    navigate(`/?show_greeting=true`)
  }

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Login' })
  }, [])

  return (
    <>
      <Layout title="Inicia sesión">
        <Wrapper>
          <Inner>
            <BackButton className="back-button" onClick={() => navigate(-1)}>
              <Icon iconId="arrow_left" />
              Volver
            </BackButton>
            <AuthWidget
              showWidget={() => null}
              closeWidget={() => null}
              onClose={() => null}
              title="Inicia sesión"
              useModal={false}
              formSettings={{
                type: AuthFormType.SIGNIN,
                props: {
                  userLoginCartHandler,
                  onFinished: onSignInFinished,
                  showNoAccount: false,
                },
              }}
            />
          </Inner>
        </Wrapper>
      </Layout>
      <UserLoginHandlerAlertComponent onFinished={onSignInFinished} />
    </>
  )
}

export default withPageTransition(LoginPage)
